html, body {
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.App {
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
}

.response {
  height: 50%;
  text-align: center;
}

.information {
  display: flex;
  width: 50%;
  background-color: rgb(245, 200, 128);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #474742; /* Color de fondo */
  color: #fff; /* Color del texto */
  border: none;
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

